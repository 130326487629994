import React from 'react';
import loadable from '@loadable/component';
import Loading from '@components/Loading';

export const AdminList = loadable(() => import(/* webpackChunkName: "admin-role-create" */ './AdminListManagement'), {
  fallback: <Loading />,
});

export const AdminCreate = loadable(
  () => import(/* webpackChunkName: "admin-role-create" */ './AdminCreateManagement'),
  {
    fallback: <Loading />,
  },
);
export const AdminUpdate = loadable(() => import(/* webpackChunkName: "admin-role-create" */ './AdminEditManagement'), {
  fallback: <Loading />,
});
