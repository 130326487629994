import React, { Suspense, useContext } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Layout, LayoutSplashScreen, ContentRoute } from '@metronic/layout';
import { UserContext } from '@context/user';
import Navbar from '@components/Navbar';
import DashboardRoute from '@routes/Dashboard';
import { Login, Unauthorized, Forgot } from '@routes/Auth';
import { TemplateCreate, TemplateEdit, TemplateList } from '@routes/TemplateManagement';
import { ClientCreate, ClientEdit, ClientList } from '@routes/ClientManagement';
import { AdminRoleCreate, AdminRoleEdit, AdminRoleList } from '@routes/AdminRoleManagement';
import { ProductList, ProductCreate, ProductEdit } from '@routes/ProductManagement';
import { BlogCreate, BlogEdit, BlogList } from '@routes/BlogManagement';
import { OrderEdit, OrderList } from '@routes/OrderManagement';
import { AdminList, AdminCreate, AdminUpdate } from '@routes/AdminManagement';

import { CategoryList, CategoryCreate, CategoryUpdate } from '@routes/KategoriManagement';

// eslint-disable-next-line react/prop-types
const BasePage = ({ hasAccess }) => {
  // eslint-disable-next-line react/prop-types
  const AccessRoute = ({ permission, component, path, exact }) => {
    if (hasAccess(permission)) {
      return <ContentRoute exact={exact} path={path} component={component} />;
    }
    return <></>;
  };

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <AccessRoute exact permission="dashboard.read" path="/dashboard" component={DashboardRoute} />
        <Redirect exact from="/" to="/dashboard" />

        <AccessRoute exact permission="user.read" path="/client-management" component={ClientList} />
        <AccessRoute exact permission="user.create" path="/client-management/create" component={ClientCreate} />
        <AccessRoute exact permission="user.read" path="/client-management/:id/detail" component={ClientEdit} />

        <AccessRoute exact permission="employeeRole.read" path="/admin-role-management" component={AdminRoleList} />
        <AccessRoute
          exact
          permission="employeeRole.create"
          path="/admin-role-management/create"
          component={AdminRoleCreate}
        />
        <AccessRoute
          exact
          permission="employeeRole.read"
          path="/admin-role-management/:id/detail"
          component={AdminRoleEdit}
        />
        <AccessRoute exact permission="article.read" path="/blog-management" component={BlogList} />
        <AccessRoute exact permission="article.create" path="/blog-management/create" component={BlogCreate} />
        <AccessRoute exact permission="article.read" path="/blog-management/:id/detail" component={BlogEdit} />
        <AccessRoute permission="product.read" exact path="/product-management" component={ProductList} />
        <AccessRoute permission="product.create" exact path="/product-management/create" component={ProductCreate} />
        <AccessRoute permission="product.read" exact path="/product-management/:slug/detail" component={ProductEdit} />
        <AccessRoute exact permission="order.read" path="/order-management" component={OrderList} />
        <AccessRoute exact permission="order.read" path="/order-management/:id/detail" component={OrderEdit} />
        <AccessRoute permission="design.read" exact path="/design-template" component={TemplateList} />
        <AccessRoute permission="design.create" exact path="/design-template/create" component={TemplateCreate} />
        <AccessRoute permission="design.read" exact path="/design-template/:id/detail" component={TemplateEdit} />
        <AccessRoute permission="category.read" exact path="/category-management" component={CategoryList} />
        <AccessRoute permission="category.create" exact path="/category-management/create" component={CategoryCreate} />
        <AccessRoute
          permission="category.read"
          exact
          path="/category-management/:id/detail"
          component={CategoryUpdate}
        />
        <AccessRoute permission="employee.read" exact path="/admin-management" component={AdminList} />
        <AccessRoute permission="employee.create" exact path="/admin-management/create" component={AdminCreate} />
        <AccessRoute permission="employee.read" exact path="/admin-management/:id/detail" component={AdminUpdate} />
      </Switch>
    </Suspense>
  );
};

const AuthPages = () => {
  return (
    <Switch>
      <ContentRoute exact path="/auth/login" component={Login} />
      <ContentRoute exact path="/auth/forgot" component={Forgot} />
      <ContentRoute path="/" component={Unauthorized} />
    </Switch>
  );
};

export default function Routes() {
  const userContext = useContext(UserContext);
  return (
    <Switch>
      {!userContext.userState.isAuthorized ? (
        <>
          <Redirect from="/" to="/auth/login" />
          <AuthPages />
        </>
      ) : (
        <>
          <Navbar />
          <Layout>
            <BasePage hasAccess={userContext.hasAccess} />
          </Layout>
        </>
      )}
    </Switch>
  );
}
